//MUI MATERIALS
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

//STYLES
import authenticationUseStyles from './authenticationUseStyles'

// UTILITIES
import { getDashboardLogo } from 'utilities/logo'

function Copyright() {
  return (
    <Typography variant='caption' color='text.secondary' align='center'>
      {'©'}
      {new Date().getFullYear()}
      {' PT. Lacak Cipta Aktual'}
    </Typography>
  )
}

const getBackground = () => {
  if (process.env.REACT_APP_DASHBOARD_TYPE === 'LACAK') return 'https://api.lacak.io/static/paas/1/login_wallpaper.jpeg?1697186227746'
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'GMM') return 'https://gmmteletech.com/api/static/paas/1/login_wallpaper.jpeg' 
}

const Authentication = (props) => {
  const { children } = props
  const classes = authenticationUseStyles()

  return (
    <Stack 
      component='main' 
      direction='row'
      height='100vh' 
      className='no-zoom' 
      maxWidth='100vw'
      width='100vw'
    >
      <Stack className={`${classes.formStyle} zoom`}>
        <Box
          component='img'
          width={185}
          alt='Lacak io.'
          src={getDashboardLogo()}
        />
        {children}
        <Copyright/>
      </Stack>
      <Stack
        flex={1}
        height='100%'
      >
        <Box
          component='img'
          src={getBackground()}
          className={`${classes.backgroundStyle}`}
        />
      </Stack>
    </Stack>
  )
}

export default Authentication